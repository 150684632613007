import { Injectable } from '@angular/core';
import { DcmViewer, DcmViewerImage, DcmViewerTool } from '../models/dcm-viewer.model';
import { DispatchService, DcmMessage } from './dispatch.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CDK_ROW_TEMPLATE } from '@angular/cdk/table';
import { DcmWorklistItem, DcmWorklistSopItem } from 'src/app/modules/shared/services/dicom/models/dcm-worklist.model';
import { DcmWorklistService } from './dcm-worklist.service';





@Injectable()
export class DcmViewerStateService {

  private overlayConfig = [
    /* this should be a user preference */
    { pos: 'top-left', key: 'patientName', prefix: '', suffix: '', },
    { pos: 'top-left', key: 'patientAge', prefix: '', suffix: '' },
    { pos: 'top-left', key: 'institutionName', prefix: '', suffix: '' },

    { pos: 'top-right', key: 'bodyPartExamined', prefix: '', suffix: '' },
    { pos: 'top-right', key: 'studyDate', prefix: '', suffix: '' },

    { pos: 'bottom-left', key: 'dimensions', compose: [{ key: 'rows' }, 'x', { key: 'columns' }] },
    { pos: 'bottom-left', key: 'seriesInstanceNumber', prefix: '', suffix: '', compose: [{ key: 'seriesNumber' }, ':', { key: 'instanceNumber' }] },

    { pos: 'bottom-right', key: 'scale', prefix: '', compose: [{ key: 'scale', func: 'PERCENT' }, '%'] },
    { pos: 'bottom-right', key: 'valueOfInterest', prefix: '', suffix: '', compose: ['W:', { key: 'windowWidth', func: 'ROUND' }, 'C', { key: 'windowCenter', func: 'ROUND' }] },
  ];


  public gridPositionToDrop: any = false;


  private abstractViewer_: DcmViewer = {
    rows: 1,
    columns: 1,
    activeTool: undefined,
    viewerImages: {},
    selectVierImageIndex: undefined,
  }

  public get viewerImages(): DcmViewerImage[] {
    return Object.values(this.abstractViewer_.viewerImages);
  }
  public get abstractViewer(): DcmViewer {
    return this.abstractViewer_;
  }


  private messages$: Observable<DcmMessage>;

  private messageListener: Subscription;

  constructor(private dispatcher: DispatchService, private worklistService: DcmWorklistService) {

    this.messages$ = dispatcher.broadcast$.pipe(tap(m => {

      m === DcmMessage.IMAGE_DRAG_START && (this.gridPositionToDrop = false);

    }));

    this.messageListener = this.messages$.subscribe();

  }


  public setGridLayout(x: number, y: number) {
    this.abstractViewer_.rows = x;
    this.abstractViewer_.columns = y;

     
    this.setImagesToLayout();
    this.dispatcher.broadcast(DcmMessage.LAYOUT_CHANGED);

  }

  

  public clearImageFromViewer() {
    this.abstractViewer_.viewerImages = {};
    this.dispatcher.broadcast(DcmMessage.DISPLAY_IMAGES);

  }

  /**
   * 
   * @param sopItem 
   * @param tile  number or grid pos
   */
  public displayImage(sopItem: DcmWorklistSopItem,tile:number|any) {
    if (!(sopItem.imageId && sopItem.blobUrl)) throw 'image is missing blob url'
    const {row,column} = isNaN(tile) ?  tile : this.getTilePosition(tile);
    const aImg = this.instanceToAbstractImage(sopItem, row, column);

    const key = row + '_' + column;
    this.abstractViewer.viewerImages[key] = aImg;
    this.dispatcher.broadcast(DcmMessage.DISPLAY_IMAGES);
    return true;
  }

  

  private instanceToAbstractImage = (inst: DcmWorklistSopItem, row: number, column: number): DcmViewerImage => {
    return {
      imageId: inst.SOPInstancesUID,
      row: row,
      column: column,
      blobUrl: inst.blobUrl,
      overLayItems: this.overlayConfig,
      tile:this.getPositionByTile(row,column),
      toolState: {
        rotation: 0,
        vflip: false,
        hflip: false,
        translationChanged: false,
        scaleChanged: false,
        hasLines: false,
        hasAngles: false,
        voiChanged: false,
      }
    }

  }

  public getOutOfViewImages():string[]{
    return  this.viewerImages.filter(vImg=> vImg.tile > this.largestTile).map(img=> img.imageId);
  }

  private get largestTile (){
    return (this.abstractViewer.rows -1) * this.abstractViewer.columns + this.abstractViewer.columns;
  }

  public getPositionByTile(row:number, col:number){
    return  (row-1)*this.abstractViewer.columns + col; 
  }

  public getTilePosition(tile:number){
    // const rows = this.abstractViewer.rows
    const columns = this.abstractViewer.columns
    const col_ = tile % columns;
    return { row: Math.ceil(tile /columns)  , column : col_ == 0 ? columns : col_};  
  }

  public swapImgPositions(tileDest, tileSource){
    const sourceImage = this.viewerImages.find(a=>a.tile == tileSource)
    const destImage = this.viewerImages.find(b=>b.tile == tileDest)
    sourceImage.tile = tileDest;
    destImage.tile = tileSource;
    this.setImagesToLayout();

  }

  private setImagesToLayout(){
    const tmp = {};
    this.viewerImages.forEach(img => {
      const {row,column}  =  this.getTilePosition(img.tile);
      img.row = row;
      img.column = column;
      tmp[row + '_' + column] = img;
    }) 
    this.abstractViewer.viewerImages = tmp;
    this.dispatcher.broadcast(DcmMessage.DISPLAY_IMAGES);
  }


  /* ----------------------------------------------tools----------------------------------------- */


  private ruleSet = () => {

  }



  public setToolActive(t: DcmViewerTool) {
    this.abstractViewer.activeTool = t;
    this.dispatcher.broadcast(DcmMessage.TOOL_CHANGED);
  }
}


