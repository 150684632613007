import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { concatMap, expand, last, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { DcmWorklistItem, DcmWorklistSeriesItem, DcmWorklistSopItem } from '../models/dcm-worklist.model';
import { PacsAdaptor } from './pacs-adaptor.interface';

// const PACS_URL = 'https://orthancbackup.brightviewradiology.com';
// let PACS_URL = 'https://orthanc01.brightviewradiology.com';
let PACS_URL = '';


interface RowResponse {
  ID: string;
}

interface StudyMainDicomTags {
  StudyInstanceUID: string;
  StudyDate: string;
  StudyDescription: string;
  InstitutionName: string;
  AccessionNumber: string;
  ReferringPhysicianName: string
  StudyID: string;
  StudyTime: string;
}

interface SeriesMainDicomTags {
  BodyPartExamined: string;
  Modality: string;
  SeriesDate: string
  SeriesDescription: string;
  SeriesInstanceUID: string
  SeriesNumber: string
  SeriesTime: string;

}
interface InstanceMainDicomTags {
  InstanceCreationDate: string
  InstanceCreationTime: string;
  InstanceNumber: string
  SOPInstanceUID: string
}
interface PatientMainDicomTags {
  PatientName: string;

  PatientID: string;
  PatientBirthDate: string;
}

export interface SeriesRowResponse extends RowResponse {
  LastUpdate: string;
  MainDicomTags: SeriesMainDicomTags;
  ParentStudy: string;
  Instances: string[];
}
export interface InstanceRowResponse extends RowResponse {
  MainDicomTags: InstanceMainDicomTags;
  ParentSeries: string;
}

export interface StudyRowResponse extends RowResponse {
  MainDicomTags: StudyMainDicomTags;
  PatientMainDicomTags: PatientMainDicomTags;
  LastUpdate: string;
  Series: string[];
  SeriesRows?: SeriesRowResponse[]
  instanceRows?: InstanceRowResponse[];
}




@Injectable({providedIn:'root'})
export class OrthancAdaptorService implements PacsAdaptor {

  constructor(private http: HttpClient) {

  }

  public setRootUrl(url) {
    PACS_URL = url;
  }

  private totalStudies: number;

  private allStudies: DcmWorklistItem[];


  getStudies(page: number, limit: number, q: string = '') {
    const since = (page) * limit;
    const url = `${PACS_URL}/studies?expand=true&since=${since}&limit=${limit}`;
    return this.http.get(url)
  }

  getWorklist(page: number, limit: number, q: string = '') {

    return this.getStudies(page, limit).pipe(map((list: any[]) => this.studiesToWorklist(list)));

  }

  public studiesToWorklist(studies: StudyRowResponse[], ignorePhi = false): DcmWorklistItem[] {
    return studies.map(row => {
      const mainTags = row.MainDicomTags;
      const PatientTags = row.PatientMainDicomTags;
      const sopInstances: DcmWorklistSopItem[] = row.instanceRows ? row.instanceRows.map(iRow => ({ sopUniqueId: iRow.ID, SOPInstancesUID: iRow.MainDicomTags.SOPInstanceUID })) : []
      const series: DcmWorklistSeriesItem[] = row.SeriesRows ?
        row.SeriesRows.map(sRow => ({ seriesUniqId: sRow.ID, seriesInstanceUID: sRow.MainDicomTags.SeriesInstanceUID, seriesNumber: sRow.MainDicomTags.SeriesNumber, sopInstances: sopInstances.filter(s => sRow.Instances.includes(s.sopUniqueId)) })) :
        row.Series.map(x => ({ seriesUniqId: x, sopInstances: sopInstances.filter(sop => sop.sopUniqueId) }));
      const workListItem: DcmWorklistItem = {
        studyUniqId: row.ID,
        studyInstanceUID: mainTags.StudyInstanceUID,
        series: series,
        lastUpdate: row.LastUpdate,
        studyInfo: ignorePhi ? undefined : {
          patientName: PatientTags.PatientName || '',
          studyDate: mainTags.StudyDate || '',
          studyDescription: mainTags.StudyDescription || '',
          institutionName: mainTags.InstitutionName || '',
          patientId: PatientTags.PatientID || '',
          patientBirthDate: PatientTags.PatientBirthDate || '',
          misc: row,
        }
      }
      return workListItem
    });
  }

  setImageUrls(worklistItem: DcmWorklistItem) {

    const seriesRequest$ = this.http.get<any[]>(`${PACS_URL}/studies/${worklistItem.studyUniqId}/series?expand=true`);
    const instanceRequest$ = this.http.get<any[]>(`${PACS_URL}/studies/${worklistItem.studyUniqId}/instances?expand=true`);
    return forkJoin(seriesRequest$, instanceRequest$).pipe(
      map(res => {
        const seriesData: any[] = res[0];
        const instanceData: any[] = res[1];

        worklistItem = seriesData.reduce((acc: DcmWorklistItem, val) => {
          const seriesUniqueId = val['ID'];
          const series = acc.series.find((ser: DcmWorklistSeriesItem) => ser.seriesUniqId == seriesUniqueId);
          series.seriesNumber = val.MainDicomTags.SeriesNumber;
          series.seriesInstanceUID = val.MainDicomTags.SeriesInstanceUID;
          series.sopInstances = instanceData.filter(inst_ => inst_.ParentSeries == seriesUniqueId)
            .map(inst => {
              const instUniqueID = inst['ID'];
              return {
                sopUniqueId: instUniqueID,
                SOPInstancesUID: inst.MainDicomTags.SOPInstanceUID,
                instanceNumber: inst.MainDicomTags.InstanceNumber,
                getUrl: `${PACS_URL}/instances/${instUniqueID}/file`,
                loaded: false,
                displayedAt: undefined,
              }
            });
          return worklistItem;

        }, worklistItem);


        return worklistItem;
      })/* , tap(v=>console.log(v)) */);

  }


  public loadImage(url): Observable<any> {
    return this.http.get(url, { responseType: 'blob' });
  }

  public getRendered(instance: DcmWorklistSopItem): Observable<Blob> {
    return this.http.get(`${PACS_URL}/instances/${instance.sopUniqueId}/preview`, {
      responseType: 'blob',
      headers: new HttpHeaders({
        accept: "image/jpeg"
      })
    });

  }


  private URL = `${PACS_URL}/studies?expand=true`;
  // private URL = `${PACS_URL}/studies?expand=true&limit=100&since=0`;
  public getAllStudies = (uidLIst?: string[]): Observable<DcmWorklistItem[]> => uidLIst ? this.getUidList(uidLIst) : (this.allStudies ? of(this.allStudies) :
    this.http.get(this.URL)).pipe(map((raw: any[]) => this.studiesToWorklist(raw, false)))


  private getUidList(uidLIst: string[]): Observable<DcmWorklistItem[]> {
    const uidListString = uidLIst.join("\\");
    if (uidLIst.length < 1) throw new Error('Invalid Uid list');
    return this.http.post(`${PACS_URL}/tools/find`, {
      Expand: true,
      Level: "instance",
      Query: { StudyInstanceUID: uidListString },

    }).pipe(
      concatMap((instanceResult: any[]) => {
        return this.http.post(`${PACS_URL}/tools/find`, {
          Expand: true,
          Level: "series",
          Query: { StudyInstanceUID: uidListString },

        }).pipe(map(seriesResult => ({ instanceResult: instanceResult, seriesResult: seriesResult })))
      }),
      concatMap((result: any) => {
        const instanceResult: any[] = result.instanceResult;
        const series: any[] = result.seriesResult;
        return this.http.post(`${PACS_URL}/tools/find`, {
          Expand: true,
          Level: "Study",
          Query: { StudyInstanceUID: uidListString },

        }).pipe(map(study => ({ studyResult: study, seriesResult: series, instanceResult: instanceResult })))
      }),
      map((result: any) => {
        let seriesResult: SeriesRowResponse[] = result.seriesResult;
        let instanceResult: InstanceRowResponse[] = result.instanceResult;
        let studyResult: StudyRowResponse[] = result.studyResult
        studyResult = studyResult.map(studyRow => {
          studyRow.SeriesRows = seriesResult.filter(x => x.ParentStudy == studyRow.ID);
          studyRow.instanceRows = instanceResult.filter(y => studyRow.Series.includes(y.ParentSeries))
          return studyRow;
        })
        return this.studiesToWorklist(studyResult);
      }));
  }


  public getStudyMetadata(studyInstanceUid: string, suppliedUrl: string = undefined) {
    const url = suppliedUrl ? `${suppliedUrl}/metadata` : `${PACS_URL}/studies/${studyInstanceUid}/metadata`;
    return this.http.get(url, {
      headers: new HttpHeaders({
        accept: 'application/dicom+json',
      })
    }).pipe(map((result: any) => this.studiesToWorklist([result], false)[0]))
  }


  public uploadInstance(file: File): Observable<HttpEvent<ArrayBuffer>> {

    // tslint:disable-next-line: max-line-length
    return this.http.post<any>(`${PACS_URL}/studies`, file, {
      headers: new HttpHeaders({
        'Content-Type': 'application/dicom',
        Accept: 'application/dicom+json',
      }),
      reportProgress: true,
      observe: 'events',
      // responseType:'arraybuffer'
    });

  }

  public test(limit, since) {

  }


  private getAllChanges(since: number): Observable<ChangesResponse> {
    let counter = 0;

    const studyChanges: OrthancChange[] = [];
    let lastChange: number;
    let start = 0;
    const getLatestStudyChanges = (since: number) => this.http.get<ChangesResponse>(`${PACS_URL}changes?since=${since}`).pipe(
      tap(x => studyChanges.push(...x.Changes)),
      tap(x => lastChange = x.Last),
      // expand(res => res.Done ? EMPTY : getLatestStudyChanges(start))// <-- oh my my my :(
      // switchMap(res => res.Done ? of(null) : getLatestStudyChanges(start)) // <--totally fine 
    );

    return of({ Last: since }).pipe(
      expand(res => getLatestStudyChanges(res.Last)),
      takeWhile(res => !res.Done && ++counter < 50), last(),
      map(() => ({ Last: lastChange, Changes: studyChanges, Done: true, }))
    );
  }

  public getStudyByUID<OrthancStudyInfo>(uid: string): Observable<OrthancStudyInfo> {
    return this.http.get<StudyRowResponse>(`${PACS_URL}studies/${uid}`).pipe(switchMap(studyResult => this.http.get<OrthancStudyInfo>(`${PACS_URL}studies/${uid}/shared-tags?simplify`).pipe(
      map(study => Object.assign(studyResult, studyResult.MainDicomTags, studyResult.PatientMainDicomTags, study, { LastChange: 0 })))
    ))
  }

  public getLatestStudies<OrthancStudyInfo>(since: number, limit?: number): Observable<OrthancStudyInfo[]> {

    const mkStudyRequest = (change: OrthancChange, lastChange: number) => this.http.get<StudyRowResponse>(`${PACS_URL}${change.Path}`).pipe(
      switchMap(studyResult => this.http.get<OrthancStudyInfo>(`${PACS_URL}${change.Path}/shared-tags?simplify`).pipe(
        map(study => Object.assign(studyResult, studyResult.MainDicomTags, studyResult.PatientMainDicomTags, study, change, { LastChange: lastChange })))
      )
    );

    return this.getAllChanges(since).pipe(
      map(changes => {
        const ordered: any = changes.Changes.filter(change => change.ChangeType === 'StableStudy').sort((a, b) => a.Seq - b.Seq).slice(0, limit);
        const lastInSequence = ordered[ordered.length - 1]?.Seq;
        const lastChange = lastInSequence ? Math.min(changes.Last, lastInSequence) : changes.Last;
        return ordered.map(x => mkStudyRequest(x, lastChange)) as OrthancStudyInfo[]
      }),
      concatMap(studyTags$ => studyTags$.length > 0 ? forkJoin(studyTags$) : of([]))
    );
  }

  public getInstanceMetadata(study: OrthancStudyInfo): Observable<OrthancMetadata[]> {


    const requests$ = study.Series.map(seriesOrthancID => this.http.get(`${PACS_URL}/series/${seriesOrthancID}`).pipe(
      concatMap((result: any) => {
        const list$: Observable<any>[] = result.Instances.map(instanceOrthancId => this.http.get(`${PACS_URL}instances/${instanceOrthancId}/metadata?expand`))
          .map(x => x.pipe(map(metadataResult => Object.assign(result, metadataResult, result?.MainDicomTags) as OrthancMetadata)))

        return combineLatest(list$);
      })));
    const result$ = requests$.reduce((acc, v) => acc.concat(v), []) as Observable<OrthancMetadata>[]
    return combineLatest(result$)
  }





}

export interface OrthancMetadata {
  CalledAET: string;
  IndexInSeries: number;
  Origin: string;
  ReceptionDate: string;
  RemoteAET: string;
  RemoteIP: string;
  SopClassUid: string;
  TransferSyntax: string;
  BodyPartExamined: string;
  Manufacturer: string;
  Modality: string;
  OperatorsName: string,
  SeriesDate: string;
  SeriesDescription: string;
  SeriesInstanceUID: string;
  SeriesNumber: string;
  SeriesTime: string;
}


export interface ChangesResponse {
  Changes: OrthancChange[];
  Done: boolean;
  Last: number;
}

export interface OrthancChange {
  ChangeType: 'StableSeries' | 'StableStudy' | 'StablePatient' | 'NewStudy',
  Date: string,
  ID: string,
  Path: string,
  ResourceType: 'Series' | 'Study' | 'Patient';
  Seq: number
}

export interface OrthancStudyInfo extends OrthancChange {
  LastChange: number;
  LastUpdate: string;
  AccessionNumber?: string;
  AcquisitionDate?: string,
  BitsAllocated?: string;
  BitsStored?: string;
  BodyPartExamined?: string;
  BurnedInAnnotation?: string;
  CassetteSize?: string;
  ContentDate?: string;
  ContentTime?: string;
  ContrastBol?: string;
  DeviceSerialNumber?: string,
  GantryID?: string;
  Grid?: string;
  High?: string;
  ImageType?: string;
  ImagePixelSpacing?: string;
  InstanceCreationDate?: string;
  InstanceCreationTime?: string;
  InstanceNumber?: string;
  InstitutionAddress?: string;
  InstitutionName?: string;
  InstitutionalDepartmentName?: string;
  LossyImageCompression?: string;
  Manufacturer?: string;
  ManufacturerModelName?: string;
  Modality?: string;
  OperatorsName?: string;
  PatientBirthDate?: string;
  PatientComments?: string,
  PatientID?: string;
  PatientName?: string;
  PatientSex?: string;
  PerformedProcedureStepStartDate?: string;
  PerformedProcedureStepStartTime?: string;
  PhotometricInterpretation?: string;
  PixelRepresentation?: string;
  PixelSpacing?: string;
  PlateID?: string;
  PlateType?: string;
  PresentationLUTShape?: string;
  PrivateCreator?: string;
  QualityControlImage?: string;
  ReferringPhysicianName?: string;
  RescaleIntercept?: string;
  RescaleSlope?: string;
  RescaleType?: string;
  SOPClassUID?: string;
  SOPInstanceUID: string;
  SamplesPerPixel: string;
  Sensitivity: string;
  SeriesDate: string;
  SeriesDescription: string;
  SeriesInstanceUID: string;
  SeriesNumber: string;
  SeriesTime: string;
  SoftwareVersions: string;
  StationName: string;
  StudyDate: string;
  StudyDescription: string;
  StudyID: string;
  StudyInstanceUID: string;
  StudyTime: string;
  TargetExposureIndex: string;
  ViewPosition: string;
  WindowCenter?: string;
  WindowCenterWidthExplanation?: string;
  WindowWidth?: string;
  Series: string[];
}