<div class="container" (dragover)="resetGridDragging($event)">
    <mat-progress-bar *ngIf="showSpinner | async" mode="indeterminate"></mat-progress-bar>
    <div class="toolbar">
        <div class="toolbar__left">
            <ng-container *ngTemplateOutlet="thumbnails_btn"></ng-container>
            <!-- <button (click)="showWorklist()"> Worklist</button> -->
            <ng-container *ngTemplateOutlet="levels_btn"></ng-container>
            <ng-container *ngTemplateOutlet="zoom_btn"></ng-container>
            <ng-container *ngTemplateOutlet="length_btn"></ng-container>
            <!-- <ng-container *ngTemplateOutlet="annotate_btn"></ng-container> -->
            <ng-container *ngTemplateOutlet="angle_btn"></ng-container>
            <ng-container *ngTemplateOutlet="reset_btn"></ng-container>
            <!-- <ng-container *ngTemplateOutlet="cine_btn"></ng-container> -->
            <ng-container *ngTemplateOutlet="more_btn"></ng-container>
            <ng-container *ngTemplateOutlet="layout_btn"></ng-container>
            <!-- <ng-container *ngTemplateOutlet="prev_study"></ng-container>
            <ng-container *ngTemplateOutlet="next_study"></ng-container> -->
            <ng-container *ngTemplateOutlet="prev_image"></ng-container>
            <ng-container *ngTemplateOutlet="next_image"></ng-container>
        </div>
        <div [ngStyle]="{'display':showThumbnails ? '' : 'none'}" class="toolbar__right">

            <div style="margin-right: auto;">
                <div class="thumbnail-wrap" *ngFor="let relatedThumbnail of relatedThumbnailLits">
                    <viewer-thumbnail (imageDragged)="onImageDrag($event)" [thumbnailInstanceItem]="relatedThumbnail"
                                      [thumbnailText]="relatedThumbnailStudyMap[relatedThumbnail.SOPInstancesUID]?.studyInfo?.studyDate"></viewer-thumbnail>
                </div>
            </div>
            <div class="thumbnail-wrap" *ngFor="let thumbnailItem of thumbnailLits">
                <viewer-thumbnail (imageDragged)="onImageDrag($event)" [thumbnailInstanceItem]="thumbnailItem"
                                  [thumbnailText]="worklistItem?.studyInfo?.studyDate"></viewer-thumbnail>
            </div>
        </div>
    </div>

    <iframe (dragover)="$event.preventDefault()" class="viewport-container" referrerpolicy="origin" viewerIframe
            (loaded)="null" #img_iframe></iframe>
</div>

<ng-template #layout_btn>
    <div style="display: inline-flex; position: relative">
        <app-icon-button label="Layout" icon="ri-layout-grid-fill" buttonClass="ibutton-viewer"
                         (click)="layoutVisible = !layoutVisible" (clickOutside)="layoutVisible = false">
        </app-icon-button>
        <div *ngIf="layoutVisible" class="toolbar-button__extended">
            <table class="layout-chooser">
                <tbody>
                    <tr *ngFor="let row of layoutMap; let r=index">
                        <td *ngFor="let col of row; let c=index" (mouseover)="hoverMatrix=buildHoverMatrix(r+1,c+1)"
                            (mouseout)="hoverMatrix = []"
                            [ngClass]="{'hover': hoverMatrix.indexOf(r*row.length +c+1) > -1}"
                            (click)="setUserGridLayout(r+1,c+1)">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>
<ng-template #more_btn>
    <div style="display: inline-flex; position: relative">
        <app-icon-button label="More" icon="ri-more-fill" buttonClass="ibutton-viewer"
                         (click)="moreVisible = !moreVisible" (clickOutside)="moreVisible = false">
        </app-icon-button>
        <div *ngIf="moreVisible" class="toolbar-button__extended">
           
            <div (click)="handleButtonAction(toolbarActions.INVERT)" class="toolbar-button">
                <img src="../../../../../assets/img/svg/dcm-invert.svg" alt="">
                <div class="toolbar-button-label">Invert</div>
            </div>
            <div (click)="handleButtonAction(toolbarActions.ROTATE_RIGHT)" class="toolbar-button">
                <img src="../../../../../assets/img/svg/dcm-rotate-right.svg" alt="">
                <div class="toolbar-button-label">Rotate Right</div>
            </div>
            <div (click)="handleButtonAction(toolbarActions.FLIP_H)" class="toolbar-button">
                <img src="../../../../../assets/img/svg/dcm-flip-h.svg" alt="">
                <div class="toolbar-button-label">Flip H</div>
            </div>
            <div (click)="handleButtonAction(toolbarActions.FLIP_V)" class="toolbar-button">
                <img src="../../../../../assets/img/svg/dcm-flip-v.svg" alt="">
                <div class="toolbar-button-label">Flip V</div>
            </div>
          
        </div>
    </div>

</ng-template>
<ng-template #thumbnails_btn>
    <app-icon-button label="Thumbnails" icon="ri-gallery-line"
                     [buttonClass]="{'ibutton-viewer': true, 'active': showThumbnails }"
                     (click)="showThumbnails = !showThumbnails"></app-icon-button>
</ng-template>
<ng-template #levels_btn>
    <app-icon-button label="Levels" icon="ri-contrast-line"
                     [buttonClass]="{'ibutton-viewer': true, 'active': (activeTool$ | async) == 'WINDOW_LEVEL'}"
                     (click)="setToolActive('WINDOW_LEVEL')"></app-icon-button>
</ng-template>
<ng-template #zoom_btn>
    <app-icon-button label="Zoom" icon="ri-zoom-in-line"
                     [buttonClass]="{'ibutton-viewer': true, 'active': (activeTool$ | async) == 'ZOOM_PAN'}"
                     (click)="setToolActive('ZOOM_PAN')"></app-icon-button>
</ng-template>
<!-- 
<ng-template #pan_btn>
    <div class="toolbar-button" (click)="setToolActive('Pan')" [class.active]="'Pan' === activeTool">
        <img src="../../../../../assets/img/svg/dcm-pan.svg" alt="">
        <div class="toolbar-button-label">Pan</div>
    </div>
</ng-template> -->
<ng-template #length_btn>
    <app-icon-button label="Length" icon="ri-ruler-line"
                     [buttonClass]="{'ibutton-viewer': true, 'active': (activeTool$ | async) == 'LENGTH_MEASURE'}"
                     (click)="setToolActive('LENGTH_MEASURE')"></app-icon-button>
</ng-template>
<ng-template #annotate_btn>
    <app-icon-button label="Annotate" icon="ri-text"
                     [buttonClass]="{'ibutton-viewer': true, 'active': (activeTool$ | async) == 'ANNOTATE'}"
                     (click)="setToolActive('ANNOTATE')"></app-icon-button>
</ng-template>
<ng-template #angle_btn>
    <app-icon-button label="Angle" icon="ri-arrow-left-s-line"
                     [buttonClass]="{'ibutton-viewer': true, 'active': (activeTool$ | async) == 'ANGLE_MEASURE'}"
                     (click)="setToolActive('ANGLE_MEASURE')"></app-icon-button>
</ng-template>
<ng-template #reset_btn>
    <app-icon-button label="Reset" icon="ri-restart-line" buttonClass='ibutton-viewer' (click)="reset()">
    </app-icon-button>
</ng-template>
<ng-template #prev_study>
    <app-icon-button label="Study"
                     buttonClass="ibutton-viewer"
                     [icon]="'ri-arrow-left-fill'"
                     [disabled]="!hasPrevStudy"
                     (click)="prevStudy()">
    </app-icon-button>
</ng-template>
<ng-template #next_study>
    <app-icon-button label="Study"
                     buttonClass="ibutton-viewer"
                     [icon]="'ri-arrow-right-fill'"
                     [disabled]="!hasNextStudy"
                     (click)="nextStudy()">
    </app-icon-button>
</ng-template>


<ng-template #prev_image>
    <app-icon-button label="Image"
                     buttonClass="ibutton-viewer"
                     [icon]="'ri-skip-back-line'"
                     [disabled]="1 > undisplayedPrevImageStack.length"
                     (click)="prevImg()">
    </app-icon-button>
</ng-template>
<ng-template #next_image>
    <app-icon-button label="Image"
                     buttonClass="ibutton-viewer"
                     [icon]="'ri-skip-forward-line'"
                     [disabled]="1 > undisplayedNextImageStack.length"
                     (click)="nextImg()">
    </app-icon-button>

</ng-template>
<!-- <ng-template #prev_image>
    <div class="toolbar-button" (click)="prevImg()">
        <img src="../../../../../assets/img/svg/dcm-prev-image.svg" alt="">
        <div class="toolbar-button-label">Prev Image</div>
    </div>
</ng-template>
<ng-template #next_image>
    <div class="toolbar-button" (click)="nextImg()">
        <img src="../../../../../assets/img/svg/dcm-next-image.svg" alt="">
        <div class="toolbar-button-label">Next Image</div>
    </div>
</ng-template> -->
<!-- <ng-template #cine_btn>
    <div class="toolbar-button">
        <img src="../../../../../assets/img/svg/dcm-cine.svg" alt="">
        <div class="toolbar-button-label">CINE</div>
    </div>
</ng-template> -->