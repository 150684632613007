
export interface OverLayDataItem {
    pos:'top-right'|'top-left'|'bottom-right'|'bottom-left';
    key:string,
}

/* for now the tool also describes the which btn, click controls them */
export enum DcmViewerTool {
    WINDOW_LEVEL ='WINDOW_LEVEL',
    ZOOM_PAN = 'ZOOM_PAN',
    LENGTH_MEASURE = 'LENGTH_MEASURE',
    ANGLE_MEASURE = 'ANGLE_MEASURE',
    ANNOTATE = 'ANNOTATE',
    FLIP_V = 'FLIP_V',
    FLIP_H = 'FLIP_H',
    ROTATE= 'ROTATE',
    INVERT ='INVERT',
    RESET ='RESET',
    SCROLLER = 'SCROLLER'

}

export interface DcmImgVoi {
    windowWidth:number,
    windowCenter:number,
}
export interface DcmImgTranslation {
    x:number,
    y:number,
}

export interface DcmImageToolState {
    voiChanged:boolean
    scaleChanged:boolean
    translationChanged:boolean;
    rotation?:number;
    vflip?:boolean;
    hflip?:boolean;
    hasLines:boolean;
    hasAngles:boolean;
}

export interface DcmViewerImage {
    row:number,
    column:number,
    imageId:string,
    // overLayItems:OverLayDataItem[],
    overLayItems:any[], // come back to this in a minute
    blobUrl?:string,
    toolState:DcmImageToolState,
    tile:number; // this is helpful to gt position in laout  
    
}

export interface DcmViewer {
    rows:number,
    columns:number,
    activeTool:DcmViewerTool,
    viewerImages:{[k:string]:DcmViewerImage};
    selectVierImageIndex?:any;

}