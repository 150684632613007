import { isString } from 'lodash-es';

export interface GridPosition {
    row: number,
    column: number,
}

export interface DcmWorklistSopItem {
    SOPInstancesUID?: string;
    sopUniqueId: string;
    imageId?: string;
    loaded?: boolean;
    getUrl?: string;
    loading?: boolean;
    instanceNumber?: number;
    fileSize?: number;
    blobUrl?: string,
}

export interface DcmWorklistSeriesItem {
    seriesUniqId: string;
    seriesInstanceUID?: string;
    sopInstances: DcmWorklistSopItem[];
    seriesNumber?: number;
}

export interface DcmWorklistStudyInfo {
    patientName: string;
    patientId: string;
    studyDate: string;
    studyDescription: string;
    institutionName: string;
    patientBirthDate?:string,
    misc?:any
}
export interface DcmWorklistItem {
    studyUniqId: string;
    studyInstanceUID?: string; // often the same as studyInstanceUID but noe always e.g at Orthanc.
    series: DcmWorklistSeriesItem[];
    relatedStudies?: DcmWorklistItem[]; //e.g priors
    studyInfo?: DcmWorklistStudyInfo;
    lastUpdate?:string;
}

export interface DcmWorklist {
    items: DcmWorklistItem[];
    layoutRows: number;
    layoutColumns: number;
    activeTool?: string;
    pos?:number;

}



export function sortItem(item: DcmWorklistItem) {
    item.series.sort((a, b) => a.seriesNumber - b.seriesNumber);
    item.series.forEach(s => {
        s.sopInstances.sort((a, b) => a.instanceNumber - b.instanceNumber);
    })
    return item;
}

export function getWorklistItem(q: any, list: DcmWorklistItem[]): DcmWorklistItem {
    q = isString(q) ? q : q.studyUniqId || q.seriesUniqId || q.sopUniqueId;
    return list.find(i => i.studyUniqId == q || i.series.find(s => s.seriesUniqId == q || s.sopInstances.find(inst => inst.sopUniqueId == q)));
}

export function getInstancesOfStudyItem(item: DcmWorklistItem): DcmWorklistSopItem[] {
    return item.series.reduce((acc, v) => acc.concat(v.sopInstances), []);
}


export function getInstances(wkList: DcmWorklistItem[]): DcmWorklistSopItem[] {
    return wkList.reduce((acc, studyItem) =>acc.concat(getInstancesOfStudyItem(studyItem)), []);
}





 







