import { HttpEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { isString } from 'lodash-es';
import { NEVER, Observable, firstValueFrom, from, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DcmWorklist, DcmWorklistItem, DcmWorklistSopItem } from './models/dcm-worklist.model';
import { PACS_ADAPTER, PacsAdaptor } from './pacs-adaptors/pacs-adaptor.interface';
import { getDownloadURL, getStorage, ref as storageRef, uploadBytes } from 'firebase/storage';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from 'firebase/firestore';
import { OrthancAdaptorService } from './pacs-adaptors/orthanc-adaptor.service';

export interface viewerSession {
    sessionId: string,
    controller(uid: string): void,
}

@Injectable({ providedIn: 'root' })
export class PacsConnector {

    constructor(public pacsAdaptor: OrthancAdaptorService) {
        this.checkSessionStatus();
    }




    public setSopData(item: DcmWorklistItem): Observable<DcmWorklistItem> {

        return this.pacsAdaptor.setImageUrls(item);//.pipe(map(i => (i.series = item.series.sort((a, b) => a.seriesNumber - b.seriesNumber) ,i)));
    }


    public getStudiesWorklist(page: number = 1, limit: number = 50, query: string = ''): Observable<DcmWorklistItem[]> {
        return this.pacsAdaptor.getWorklist(page, limit, query);

    }

    public checkSessionStatus() {
        if (!window.opener) {
            if (!sessionStorage.getItem('dcm-clear-cache')) {
                console.log("NO SESSION FOUND - INITIALIZING AND CLEARING CACHE");
                sessionStorage.setItem('dcm-clear-cache', new Date().toLocaleString());
                caches.delete('dcm-img-cache');

            } else {
                console.log("SESSION DETECTED. LOADING MEMORY STATS...");
                this.checkCachingStatus();
            }
        }
    }

    public async checkCachingStatus() {
        if (navigator.storage && navigator.storage.estimate) {
            const quota = await navigator.storage.estimate();
            const usage = ((quota.usage / quota.quota) * 100).toFixed(2);
            const usageMB = (quota.usage / 1024 / 1024).toFixed(2);
            const remaining = ((quota.quota - quota.usage) / 1024 / 1024).toFixed(2);
            console.log(`Cache stats: you have used ${usageMB}MB (${usage}%) of the available cache. Maximum remaining is ${remaining}MB.`);
        }
    }


    /* first attempt at error catching  */
    private imageErrorHandler = (e) => {
        /* create issue to notify user about invalid time file */
        console.log(e);
        return NEVER;
    }

    private blobsToClean = []

    public cacheImage(url): Observable<any> {
        const apiRequest = this.pacsAdaptor.loadImage(url).pipe(catchError(this.imageErrorHandler));
        if (environment.ignore_cdm_cache) return apiRequest;
        return from(caches.open('dcm-img-cache').then((c: any) => {
            return c.match(url + '/cache').then(r => {
                if (r != undefined) return r.blob().then(b => {

                    return b;
                });
                else return apiRequest.toPromise().then(b => {
                    c.put(url + '/cache', new Response(b));
                    return b;
                });


            });
        }))
    }

    public fetchImage(url): Observable<any> {
        this.blobsToClean.forEach(url_ => URL.revokeObjectURL(url_));
        this.blobsToClean = [];
        return this.cacheImage(url).pipe(map(b_ => {
            const url_ = URL.createObjectURL(b_);
            return url_;
        }))
    }

    private getPreviewImageUrl(instanceUid: string): Observable<string> {
        return from(getDownloadURL(storageRef(getStorage(), environment.dcm_preview_img_bucket + '/' + instanceUid)));
    }

    private cachePreviewImages(blob: Blob, instanceUid: string): Observable<boolean> {
        return from(
            uploadBytes(storageRef(getStorage(), environment.dcm_preview_img_bucket + '/' + instanceUid), blob)
                .then(() => true, () => false)
        );
    }

    public fetchThumbnails(instance: DcmWorklistSopItem): Observable<string> {
        const apiRequest = this.pacsAdaptor.getRendered(instance).pipe(catchError(this.imageErrorHandler));

        return this.getPreviewImageUrl(instance.SOPInstancesUID).pipe(
            concatMap(cached_preview_image_url => {
                if (!cached_preview_image_url ) {
                    return apiRequest.pipe(

                        concatMap(b => this.cachePreviewImages(b, instance.SOPInstancesUID)),
                        concatMap(v => this.getPreviewImageUrl(instance.SOPInstancesUID)) // must do another trip to get signed url 
                    );
                }
                return of(cached_preview_image_url);

            }));
    }




    public getStudyMetadata(studyInstanceUid: string, suppliedUrl: string = undefined): Observable<DcmWorklistItem> {
        return this.pacsAdaptor.getStudyMetadata(studyInstanceUid, suppliedUrl);

    }

    public uploadInstance(file: File): Observable<HttpEvent<ArrayBuffer>> {
        return this.pacsAdaptor.uploadInstance(file);
    }

    public getAllStudies = (uidLIst?: string[]) => {
        return this.pacsAdaptor.getAllStudies(uidLIst);
    }
    public studiesToWorklist = (studies: any[], ignorePhi: boolean): DcmWorklistItem[] => this.pacsAdaptor.studiesToWorklist(studies, ignorePhi);





    public async createSession(studiesList: string[]): Promise<viewerSession> {
        const worklistItems = await firstValueFrom(this.getAllStudies(studiesList));
        const session: DcmWorklist = {
            items: studiesList.map(uid => worklistItems.find(s => s.studyInstanceUID == uid)), // use studiesList to keep original order
            pos: 0,
            layoutColumns: 2,
            layoutRows: 1,

        };

        const ref = await addDoc(collection(getFirestore(), environment.dcm_session_path), session)

        const controller = (uid: string) => {
            const pos = session.items.findIndex(item => item.studyInstanceUID == uid);
            pos > -1 && updateDoc(ref, { pos: pos })
        };

        return {
            sessionId: ref.id,
            controller: controller,
        }
    }



    public async unsetRelated(uid: string) {
        const to_delete_query_snapshot = await getDocs(
            query(
                collection(getFirestore(), environment.dcm_related_studies_db),
                where('study_uid', '==', uid)
            ));
        return Promise.all(to_delete_query_snapshot.docs.map(doc => deleteDoc(doc.ref)))
    }

    public getAllRelatedIds(): Observable<any[]> {
        return from(
            getDocs(collection(getFirestore(), (environment.dcm_related_studies_db)))
                .then(query_sanpshot => query_sanpshot.docs.map(doc => doc.data()))
        );
    }


    public getRelatedIds(uid: string, firestorePath: string): Observable<string[]> {
        return from(
            getDoc(doc(getFirestore(), `${firestorePath}/${uid}`))
                .then(doc_snapshot => {
                    if (doc_snapshot.exists()) {
                        const related_uids = doc_snapshot.data()?.c_data?.related_study_uids;
                        return isString(related_uids) ? (related_uids.split(',') || []) : related_uids
                    } else {
                        return [];
                    }
                })
        );

    }

    public setRelateDcmWorklistItems(item: DcmWorklistItem, firestorePath: string): Observable<DcmWorklistItem> {
        return this.getRelatedIds(item.studyInstanceUID, firestorePath).pipe(concatMap((list: string[]) => {
            if (list && list?.length > 0) {
                return this.getAllStudies(list).pipe(map(x => {
                    item.relatedStudies = x;
                    return item;
                }));
            } else {
                return of(item);
            }
        }))
    }


}
