import { Directive, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { getBlob, getStorage, ref } from 'firebase/storage';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DcmViewerStateService } from './services/dcm-viewer-state.service';
import { DcmMessage, DispatchService } from './services/dispatch.service';


@Directive({
    selector: '[viewerIframe]',
   
    standalone: true,
})
export class ViewerIframeDirective implements OnInit {
    @Output() loaded: EventEmitter<any> = new EventEmitter<any>();
    @Output() iframeBounds: EventEmitter<any> = new EventEmitter<any>();

    private elem;
    private isIframeLoaded = false;
    private iframeLoaded$: Observable<boolean>;

    private actionSub
    private broadcastSubscriber: Subscription;

    private iframeOrigin = window.location.origin;


    private messageQueue = [];

    private syncIframe() {
        const action = () => {
            const elem = this.elemRef.nativeElement;
            elem.contentWindow.postMessage(this.viewer.abstractViewer, this.iframeOrigin);
        }
        this.isIframeLoaded ? action() : this.messageQueue.push(action);
    }

    constructor( private elemRef: ElementRef<HTMLIFrameElement>,  private viewer: DcmViewerStateService, private dispatcher: DispatchService) {

        this.elem = elemRef.nativeElement;


        this.iframeLoaded$ = fromEvent(this.elem, 'load').pipe(filter(() => this.cachedUrl !== undefined), map((e) => {
            this.isIframeLoaded = true;
            return true;
        }));




        fromEvent(window, 'message').subscribe((v: MessageEvent) => {
            if (v.data.command == 'IMAGE_OVER') this.viewer.gridPositionToDrop = v.data;
            if (v.data.command == 'IMAGE_RENDERED') {
                const d = v.data;
                const img = this.viewer.abstractViewer.viewerImages[d.row + '_' + d.column];
                img.toolState = d.toolState;
            }
            if (v.data.command == 'IMAGE_SELECTED') {
                const d = v.data;
                this.viewer.abstractViewer.selectVierImageIndex = d.row + '_' + d.column;
            }

        });
        this.broadcastSubscriber = this.dispatcher.broadcast$.pipe(tap(m => {
            m == DcmMessage.REFRESH && this.reloadIframe();
            m == DcmMessage.LAYOUT_CHANGED && this.syncIframe();
            m == DcmMessage.TOOL_CHANGED && this.syncIframe();
            m == DcmMessage.DISPLAY_IMAGES && this.syncIframe();

        })).subscribe();

        this.iframeLoaded$.subscribe(v => {
            if (!v) return;
            while (this.messageQueue.length > 0) {
                this.messageQueue.shift()();
            }
        });


        this.getIframeUrl().then(v => {

            this.elem.src = v
        });



    }

    ngOnInit() {
        this.iframeBounds.emit(this.elem.getBoundingClientRect());
    }


    ngOnDestroy(): void {
        this.broadcastSubscriber.unsubscribe();
    }



    private reloadIframe() {
        this.isIframeLoaded = false;
        this.elemRef.nativeElement.contentWindow.location.reload();
        this.viewer.abstractViewer.viewerImages = {};
        this.syncIframe();
    }



    private cachedUrl: string = undefined;
    private async getIframeUrl(): Promise<string> {
        if (!this.cachedUrl) {
            const blob = await getBlob(ref(getStorage(), environment.viewerPath));
            this.cachedUrl = URL.createObjectURL(blob);
        }
        return this.cachedUrl;
    }

}