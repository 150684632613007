import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentReference, doc, getFirestore, updateDoc } from 'firebase/firestore';
import { NEVER, Observable, concat, merge } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { DcmWorklistItem, DcmWorklistSopItem, getInstances, getInstancesOfStudyItem, getWorklistItem } from 'src/app/modules/shared/services/dicom/models/dcm-worklist.model';
import { PacsConnector } from 'src/app/modules/shared/services/dicom/pacs-connector.service';
import { ReadingTicketWorklistItem, Worklist } from 'src/app/modules/shared/services/study-reading-ticket.model';
import { firestoreRefValuesChanges } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { ViewerIframeDirective } from '../../img-iframe.directive';
import { DcmViewerTool } from '../../models/dcm-viewer.model';
import { DcmViewerStateService } from '../../services/dcm-viewer-state.service';
import { DcmWorklistService } from '../../services/dcm-worklist.service';
import { DcmMessage, DispatchService } from '../../services/dispatch.service';
import { IconButtonComponent } from 'src/app/modules/shared';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ThumbnailComponent } from '../thumbnail/thumbnail.component';

export interface GridPosition {
    row: number,
    column: number,
}

export enum ToolbarActions {
    ROTATE_RIGHT = 'ROTATE_RIGHT',
    FLIP_V = 'FLIP_V',
    FLIP_H = 'FLIP_H',
    INVERT = 'INVERT'
}
@Component({
    selector: 'dcm-viewer',
    templateUrl: './dcm-viewer.component.html',
    styleUrls: [
        './dcm-viewer.component.scss',

    ],
    providers:[DcmViewerStateService, DispatchService,DcmWorklistService],
    standalone: true,
    imports: [CommonModule, ViewerIframeDirective, IconButtonComponent,
    MatProgressBarModule,ThumbnailComponent]
})

export class DcmViewerComponent implements OnInit {
    public layoutMap: string[][] = [];
    public imageStacksMap: { [pos: string]: string[] } = {};
    public moreVisible = false;
    public layoutVisible = false;
    public hoverMatrix = [];
    public showSpinner: Observable<boolean>;
    public activeTool$: Observable<DcmViewerTool>;
    public alive = true;
    public worklistItem: DcmWorklistItem;
    public thumbnailLits: DcmWorklistSopItem[] = [];
    public relatedThumbnailLits: DcmWorklistSopItem[] = [];
    public relatedThumbnailStudyMap: { [k: string]: DcmWorklistItem } = {};
    public showThumbnails: boolean = true;
    public toolbarActions = ToolbarActions;
    public hasNextStudy: boolean;
    public hasPrevStudy: boolean;
    public undisplayedNextImageStack: string[] = []; // images which belong to the study but are not currently displayed. 
    public undisplayedPrevImageStack: string[] = []; // images which belong to the study but are not currently displayed. 
    private userLayout = { rows: 1, columns: 1 }

    @Input('sessionId') public sessionId: string;


    private counter = 0

    constructor(
        private viewer: DcmViewerStateService,
        public pacsService: PacsConnector,
        private worklistService: DcmWorklistService,
        private dispatcher: DispatchService,

        private router: Router,
    ) {
        this.initLayout(3, 3);
    }

    private ref: DocumentReference;

    private dcmWorklist_: DcmWorklistItem[] = [];

    private pos: number;



    public get dcmWorklist() {
        return this.dcmWorklist_;
    }

    public set dcmWorklist(dcmWorklist: DcmWorklistItem[]) {
        this.dcmWorklist_ = dcmWorklist;
        this.dispatcher.broadcast(DcmMessage.WORKLIST_UPDATED);
    }

    private firestorePath = ''; // quick and dirty static cix. not meant to last
    private setFirestorePath(rootUrl) {

        this.firestorePath = !rootUrl ? '' : (rootUrl == 'https://orthanc01.brightviewradiology.com/' ?
            'dcm_studies/FtzbqedoMb42OnhfTuwL/processed' :
            '/dcm_studies/jJKnF71VMVR7wqkH5i20/processed');
    }



    ngOnInit() {

        let first = true;
        this.showSpinner = this.dispatcher.broadcast$.pipe(tap(m => {

        }), map(m => getInstances(this.dcmWorklist).some(sop => sop.loading))/* ,tap(v=>console.log(v)) */);

        const reloadIframeHandler = this.getReloadIframeHandler();
        this.activeTool$ = this.dispatcher.broadcast$.pipe(filter(m => m == DcmMessage.TOOL_CHANGED), map((m) => {
            return this.viewer.abstractViewer.activeTool;
        }))
        firestoreRefValuesChanges(doc(getFirestore(), environment.firestore_paths.worklists, this.sessionId))
            .pipe(
                map(snapshot => {
                    if (!snapshot.exists()) {
                        this.router.navigate(['not_found']);
                        return NEVER;
                    }

                    const data = snapshot.data();
                    const ref = snapshot.ref;
                    this.ref = ref;
                    return data;
                })
            ).subscribe((d: Worklist<ReadingTicketWorklistItem>) => {
                this.pos = d.c_data.pos;
                this.dcmWorklist = d.c_data.items.map(x => x.dcmWorklistItem);;
                if (first) {
                    this.pacsService.pacsAdaptor.setRootUrl(d.c_data.items[0].pacsRootUrl)
                    this.setFirestorePath(d.c_data.items[0].pacsRootUrl);
                    this.setUserGridLayout(d.c_data.viewerLayoutRows, d.c_data.viewerLayoutColumns);
                    first = false;
                }
                reloadIframeHandler() && this.dispatcher.broadcast(DcmMessage.REFRESH);
                this.displayStudy(this.pos);


            });
        const prevImgNextImgSub = this.dispatcher.broadcast$.pipe(filter(m => [DcmMessage.DISPLAY_IMAGES, DcmMessage.LAYOUT_CHANGED].includes(m))).subscribe(v => {
            this.undisplayedNextImageStack = this.viewer.getOutOfViewImages()
                .filter(imgId => !this.undisplayedPrevImageStack.includes(imgId));
        });
    }


    // Goes to the prev image using the PREV IMAGE toolbar button
    public prevImg() {
        const imageId = this.undisplayedPrevImageStack.pop();
        const tileSource = this.viewer.viewerImages.find(img => img.imageId == imageId).tile;
        const dropTile = this.getDropPositions();
        this.viewer.swapImgPositions(dropTile, tileSource);
    }

    public nextImg() {
        const imageIdSource = this.undisplayedNextImageStack.shift();
        const tileSource = this.viewer.viewerImages.find(img => img.imageId == imageIdSource).tile;
        const dropTile = this.getDropPositions();
        this.undisplayedPrevImageStack.push(this.viewer.viewerImages.find(i => i.tile == dropTile).imageId);
        this.viewer.swapImgPositions(dropTile, tileSource);



    }

    private getDropPositions() {
        /* this is only one strategy of displaying to the most right position*/
        const viewer = this.viewer.abstractViewer;
        return this.viewer.viewerImages.sort(x => x.tile).find(im => im.tile == viewer.rows * viewer.columns).tile;
    }

    private getReloadIframeHandler() {
        let counter = 1;
        return () => {
            /* this is just a simple strategy: reload after 8 studies @todo enhance and allow user say on this */
            return ++counter % 8 == 0 ? true : false;
        }
    }

    public getLayoutStrategyHandler() {
        return (userLayout, imageCount: number) => {
            /* if number of images is less then number of available tiles 
                For now: if user rows is 1 then reduce columns to number of images
                else use userLayout
            */
            if (userLayout.rows > 1) return userLayout
            if (imageCount < userLayout.columns) return { rows: 1, columns: imageCount };
            else return userLayout;

        }
    }

    private cacheAhead(pos: number, untilPos: number) {

        const cacheRequests$ = this.dcmWorklist.slice(pos, untilPos).map(item => this.worklistService.cacheWorklistItem(item));
        merge(...cacheRequests$).subscribe(/* v => console.log(v) */);
    }


    private displayStudy(pos) {

        const setLayoutHandler = this.getLayoutStrategyHandler();




        this.undisplayedPrevImageStack = [];
        this.thumbnailLits = [];
        this.viewer.clearImageFromViewer();
        const study: DcmWorklistItem = this.dcmWorklist[pos];


        this.viewer.setToolActive(DcmViewerTool.WINDOW_LEVEL);
        let first = true;

        this.pacsService.setRelateDcmWorklistItems(study, this.firestorePath).pipe(mergeMap(sdy => {
            const studies$ = [this.worklistService.loadImages(study), ...(sdy.relatedStudies || []).map(y => this.worklistService.loadImages(y))]
            return concat(...studies$);

        })).subscribe({
            next: sopInstance => { // remember there is no guarantee of a  sop list before this call. 
                const instances = [...getInstancesOfStudyItem(study), ...(study.relatedStudies || []).reduce((acc, v) => acc.concat(getInstancesOfStudyItem(v)), [])];
                if (first) {
                    first = false;
                    const { rows, columns } = setLayoutHandler(this.userLayout, instances.length);
                    this.viewer.setGridLayout(rows, columns);
                }
                const tile = instances.findIndex(sop => sop.SOPInstancesUID == sopInstance.SOPInstancesUID) + 1;
                this.viewer.displayImage(sopInstance, tile);

            },
            complete: () => {

                first = true;
                this.displayThumbnail(pos) //needs to be called on complete so sop data is loaded
                this.displayRelatedThumbNails(pos);

                this.cacheAhead(this.pos, this.pos + 5); // I still need to determine if this is the best spot to call @todo
            }
        })


        if (study.relatedStudies?.length > 0) {

            this.worklistService.loadRelatedImages(study).subscribe(v => console.log(v))
        }
        // this.displayRelatedThumbNails(pos);

        this.viewer.abstractViewer.selectVierImageIndex = '1_1';
        this.hasNextStudy = this.dcmWorklist[pos + 1] ? true : false;
        this.hasPrevStudy = this.dcmWorklist[pos - 1] ? true : false;
    }

    private displayThumbnail(pos) {
        const study: DcmWorklistItem = this.dcmWorklist[pos];
        this.worklistItem = study;
        this.thumbnailLits = getInstancesOfStudyItem(study)
    }

    private displayRelatedThumbNails(pos) {
        const relatedStudies: DcmWorklistItem[] = this.dcmWorklist[pos].relatedStudies;
        if (!relatedStudies) return;

        this.relatedThumbnailLits = relatedStudies.reduce((p, c) => p.concat(getInstancesOfStudyItem(c)), []);
        this.relatedThumbnailStudyMap = this.relatedThumbnailLits.reduce((acc, val) => {
            const sopUid = val.SOPInstancesUID;
            acc[sopUid] = getWorklistItem(val, relatedStudies)
            return acc;
        }, {})
    }




    // Hover matrix
    public buildHoverMatrix = (r: number, c: number): number[] => {
        const fullRowLength = this.layoutMap[0].length;
        const matrix = [];
        for (let i = 0; i < r; i++) {
            for (let j = 0; j < c; j++) {
                matrix.push(i * fullRowLength + j + 1)
            }
        }
        return matrix;
    }

    // Create the image layout
    private initLayout(cols: number, rows: number) {
        for (let i = 1; i < rows + 1; i++) {
            const row = [];
            for (let j = 1; j < cols + 1; j++) {
                row.push('');
                this.imageStacksMap[`${i}_${j}`] = [];

            }
            this.layoutMap.push(row);
        }
    }

    // Sets the number of available layout spaces
    public setUserGridLayout(x: number, y: number) {
        this.userLayout = { rows: x, columns: y }
        this.viewer.setGridLayout(x, y);

    }

    public setToolActive(toolName: DcmViewerTool | any) {
        // this.viewer.setToolActive(toolName);
        this.viewer.setToolActive(toolName);
    }



    // Resets the image using the RESET toolbar button
    public reset() {
        const key = this.viewer.abstractViewer.selectVierImageIndex;
        if (!key) return;
        this.viewer.abstractViewer.viewerImages[key].toolState = {
            hasLines: false,
            voiChanged: false,
            hasAngles: false,
            translationChanged: false,
            scaleChanged: false,
            rotation: undefined,
            hflip: undefined,
            vflip: undefined,
        };

        this.dispatcher.broadcast(DcmMessage.TOOL_CHANGED);
    }

    // Inverts the image using MORE -> INVERT toolbar button;
    public invert(key: string) {
        console.log("INVERT");
    }

    // Rotates the image right using MORE -> ROTATE RIGHT toolbar button;
    public rotateRight(key: string) {
        let rotatePos = this.viewer.abstractViewer.viewerImages[key].toolState.rotation;
        rotatePos = rotatePos === undefined ? 0 : rotatePos;
        this.viewer.abstractViewer.viewerImages[key].toolState.rotation = rotatePos + 90;
    }

    // Flips H the image using MORE -> FLIP H toolbar button;
    public flipH(key: string) {
        let flipPos = this.viewer.abstractViewer.viewerImages[key].toolState.hflip;
        flipPos = flipPos === undefined ? false : flipPos;
        this.viewer.abstractViewer.viewerImages[key].toolState.hflip = !flipPos
    }

    // Flips V the image using MORE -> FLIP V toolbar button;
    public flipV(key: string) {
        let flipPos = this.viewer.abstractViewer.viewerImages[key].toolState.vflip;
        flipPos = flipPos === undefined ? false : flipPos;
        this.viewer.abstractViewer.viewerImages[key].toolState.vflip = !flipPos
    }

    public handleButtonAction(action: string) {
        const key = this.viewer.abstractViewer.selectVierImageIndex;
        if (!key) return;

        switch (action) {
            case this.toolbarActions.ROTATE_RIGHT: { this.rotateRight(key); break; }
            case this.toolbarActions.FLIP_H: { this.flipH(key); break; }
            case this.toolbarActions.FLIP_V: { this.flipV(key); break; }
            default: break;
        }

        this.dispatcher.broadcast(DcmMessage.TOOL_CHANGED);
    }



    // Goes to the next study using the NEXT STUDY toolbar button
    public nextStudy() {
        /* block fast click out of range */
        if (this.dcmWorklist[this.pos + 1]) {
            updateDoc(this.ref, { 'c_data.pos': this.pos + 1 })
        }
    }

    // Goes to the previous study using the PREV STUDY toolbar button
    public prevStudy() {
        if (this.dcmWorklist[this.pos - 1]) {
            updateDoc(this.ref, { 'c_data.pos': this.pos - 1 });
        }
    }


    // Resets the dragging of the thumbnails when over container
    public resetGridDragging(ev) {
        this.viewer.gridPositionToDrop = undefined;
    }

    // Handles the dragging of the thumbnail onto a layout space
    public onImageDrag(e: any) {
        if (e.ev == 'start') {
            this.dispatcher.broadcast(DcmMessage.IMAGE_DRAG_START);
            return;
        }
        if (e.ev == 'end') {
            const dropPos: any = this.viewer.gridPositionToDrop;
            /* dropPos will only be defined if image was dropped when over a tile.
                iframe sends upstream message when `dragover` tile. 
                anywhere else gridPositionToDrop is cleared with `gridPositionToDrop` triggered by `dragover` 
                @todo this is not truly anywhere but works for now since we only have images and top toolbar 
             */
            if (dropPos) {
                const row_ = dropPos.row
                const column_ = dropPos.column;
                const destTile = this.viewer.getPositionByTile(row_, column_);
                this.viewer.viewerImages.find(im => im.tile == destTile).tile = e.sopItem.tile;
                this.viewer.displayImage(e.sopItem, destTile);

                this.viewer.gridPositionToDrop = undefined;
            }
            return;
        }
    }


}
