import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, concat, merge, of, from, pipe } from 'rxjs'
import { map, concatMap, filter, tap } from 'rxjs/operators'
import { DcmWorklistItem, DcmWorklistSopItem,sortItem, getInstances, getInstancesOfStudyItem } from 'src/app/modules/shared/services/dicom/models/dcm-worklist.model'
import { DcmImage, DcmImageUrl } from '../models/dcm-image.model';
import { DispatchService, DcmMessage } from './dispatch.service';
import { PacsConnector } from '../../shared/services/dicom/pacs-connector.service';



@Injectable()
export class DcmWorklistService {

  
  private formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

  constructor(private pacs: PacsConnector, private dispatcher:DispatchService,) {
    // window.navigator.storage.estimate().then( v=>console.log('usage' ,this.formatBytes(v.usage), 'quota' ,this.formatBytes(v.quota),v ))
  }

 
  public imagesUrlLoaded(item: DcmWorklistItem): boolean {
    const result = item.series.every(ser => ser.sopInstances.length > 0 && ser.sopInstances.every((sop: DcmWorklistSopItem) => sop.getUrl != undefined));
    return result;
  }


  public cacheWorklistItem(worklistItem: DcmWorklistItem){
    const fetchUrlInfo$ = this.imagesUrlLoaded(worklistItem) ? of(worklistItem) : this.pacs.setSopData(worklistItem);
    return fetchUrlInfo$.pipe(concatMap((worklistItem: DcmWorklistItem) => {
      worklistItem = sortItem(worklistItem);
      const urls = worklistItem.series.map(ser => ser.sopInstances.filter(i => !i.loaded).map(ins => {
        // ins.loading = true;
        this.dispatcher.broadcast(DcmMessage.WORKLIST_UPDATED);
        return { ser: ser.seriesUniqId, sop: ins.sopUniqueId, url: ins.getUrl, instance:ins }
      }))
      .reduce((acc, val) => acc.concat(val), []);
      let requests$ = urls.map(item => {
        const req =  merge(this.pacs.cacheImage(item.url));
        return req.pipe(map(r =>{
          const wItem = worklistItem.series.find(ser => ser.seriesUniqId == item.ser).sopInstances.find(i => i.sopUniqueId == item.sop);
          wItem.loaded = true;
          return true
        }))
      });
      return concat(...requests$).pipe(map(_ => worklistItem));
    }));
  }


  


  public loadImages(worklistItem: DcmWorklistItem): Observable<DcmWorklistSopItem> {

    
    // const fetchUrlInfo$ = this.imagesUrlLoaded(worklistItem) ? of(worklistItem) : this.pacs.setSopData(worklistItem);
    const fetchUrlInfo$ = this.pacs.setSopData(worklistItem);
    
    return fetchUrlInfo$.pipe(concatMap((worklistItem: DcmWorklistItem) => {
      worklistItem = sortItem(worklistItem);
      const urls = worklistItem.series.map(ser => ser.sopInstances.filter(i => !i.loaded).map(ins => {
        ins.loading = true;
        this.dispatcher.broadcast(DcmMessage.WORKLIST_UPDATED);
        return { ser: ser.seriesUniqId, sop: ins.sopUniqueId, url: ins.getUrl }
      }))
        .reduce((acc, val) => acc.concat(val), []);
      let requests$ = urls.map(item => {

        const cacheResponse =  this.pacs.fetchImage(item.url);

        return cacheResponse.pipe(map((dcmImg: any) => {
          const imageId = dcmImg;
          const wItem = worklistItem.series.find(ser => ser.seriesUniqId == item.ser).sopInstances.find(i => i.sopUniqueId == item.sop);
          wItem.imageId = imageId;
          wItem.loaded = true;
          wItem.loading = false;
          wItem.blobUrl = imageId;
          
          return item;
        }))
      });
      return concat(...requests$).pipe(map(_ => getInstancesOfStudyItem(worklistItem).find(x => x.sopUniqueId == _.sop)));
    }));

  }

  public loadRelatedImages(worklistItem: DcmWorklistItem){
    const requests$ =  worklistItem.relatedStudies.map(x => this.loadImages(x));
    return concat(...requests$); 
  }
  
}
