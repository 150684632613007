import { Component, OnInit , Input, Output, EventEmitter, Inject} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DcmWorklistService } from '../../services/dcm-worklist.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DcmWorklistSopItem } from 'src/app/modules/shared/services/dicom/models/dcm-worklist.model';
import { PacsConnector } from 'src/app/modules/shared/services/dicom/pacs-connector.service';
import { CommonModule } from '@angular/common';

export interface Idrag {
  ev :'start'|'end',
  dEv:DragEvent,
  sopItem: DcmWorklistSopItem
  
}
 
@Component({
  selector: 'viewer-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class ThumbnailComponent implements OnInit {
  @Input('thumbnailInstanceItem') private instanceItem :DcmWorklistSopItem;
  @Output('imageDragged') imgDrg :EventEmitter<Idrag> = new EventEmitter() ;
  @Input() thumbnailText: string;

  // public apiThumbnailPath$ :Observable<Blob>
  public apiThumbnailPath$ :Observable<string>
  constructor(private pacsService :PacsConnector, private worklistService:DcmWorklistService) { }

  ngOnInit(): void {
        this.apiThumbnailPath$ = this.pacsService.fetchThumbnails(this.instanceItem);
  }

 

  public notifyDragEvent(ev:'start'|'end',e:DragEvent){
    this.imgDrg.emit({
      ev:ev,
      dEv:e,
      sopItem:this.instanceItem,
    })
  }
}
