import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { DcmWorklist, DcmWorklistItem } from 'src/app/modules/shared/services/dicom/models/dcm-worklist.model';
import { filter, map, tap } from 'rxjs/operators';
import { DcmWorklistService } from './dcm-worklist.service';
import { DcmViewer, DcmViewerImage } from '../models/dcm-viewer.model';

export enum DcmMessage {
  'WORKLIST_UPDATED',
  'LAYOUT_CHANGED',
  'REFRESH',
  'IMAGE_DRAG_START',
  'IMAGE_DRAG_END',
  'TOOL_CHANGED',
  'VIEWER_STATE_CHANGED',
  'DISPLAY_IMAGES',
  'WORKLIST_STUDY_LOAD_COMPLETED',
}

@Injectable()
export class DispatchService {


  private broadcastSubject: BehaviorSubject<DcmMessage> = new BehaviorSubject(undefined);

  public viewerImages$: Observable<DcmViewerImage[]>

  public broadcast$ = this.broadcastSubject.asObservable().pipe(/* tap(m => console.log(m)) */);

  constructor() {

  }

  public broadcast = (message: DcmMessage) => {
    // console.log(message)
    this.broadcastSubject.next(message);
    return true;
  }

}
